import React from 'react';
import Footer from './Footer';
import Header from './Header';
import SignupForm from './SignupForm';
import JuneSphere from './model/JuneSphere';

const LandingPage = React.memo(() => {
    return (
        <div className="landing-container">
            <Header />
            <div className="landing-content">
                <h1 className="title">Meet June</h1>
                <p className="coming-soon">Real-Time Meeting Intelligence Platform.</p>
                <p className="coming-soon2">Real-Time AI for Meetings That Matter.</p>
                <JuneSphere />
                <SignupForm />
            </div>
            <Footer />
        </div>
    );
});

export default LandingPage;