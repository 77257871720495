import React from 'react';

const Pitch = () => {
    return (
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <iframe 
                src="https://docs.google.com/presentation/d/e/2PACX-1vS2sC9B4QeTK0_GNVZj2ryoyMEqwL-hVLhNu6FjZq0IUN8ae3Agrr2DOQp1mnVm5BTttMg6OjtuSDA8/embed?start=false&loop=false&delayms=60000" 
                frameborder="0" 
                width="1280" 
                height="749" 
                allowfullscreen="true" 
                mozallowfullscreen="true" 
                webkitallowfullscreen="true">
            </iframe>
        </div>
    );
};

export default Pitch;