import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import AboutHeader from './AboutHeader';


function About() {
  const aboutContent = `
  ## Introducing June: The Real-Time AI Platform that transforms every conversation into a strategic asset.

  ### Finally, Actionable Conversation Intelligence.

  <br />

  June’s technology is designed from the ground up to deliver real-time value and drive tangible business results.

  <br />

  - **Proprietary Edge Audio Processing:** Sub-Millisecond Latency, Unmatched Accuracy  
  - **Advanced LLM-Powered Analysis:** Contextual understanding and proactive guidance. Business Impact: Smarter, Data-Driven Decisions.  
  - **Global Reliability & Scalability** Powered by Cloudflare's Ultra-Low Latency Network. Handles massive scale and peak demand without breaking a sweat  

  <br /><br />

  For an in-depth look at June’s technical architecture and strategic roadmap, please refer to our [Pitch Deck](https://www.june.technology/pitch), take a look at our [demo](https://www.june.technology/demo),  or contact us at [june@june.technology](mailto:june@june.technology).
  `;

  return (
    <>
      <AboutHeader />
      <div className="about-container">
        <div className="about">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {aboutContent}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
}

export default About;