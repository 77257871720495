import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import AboutHeader from './AboutHeader';

function Partners() {
  const verificationContent = `
# Organization Verification

---

## Organization Legal Name:

JUNE AI LLC

<br />

## Organization Name:

June

<br />


## Registered entity information

**Name**: JUNE AI LLC

**Address**: 651 North Broad Street, Suite 201

**City**: Middletown

**State**: Delaware

**Country**: United States

**Postal code**: 19709

<br />

**Physical Address**:

June
750 Columbus Ave, 5X  
New York, NY 10025

<br />

## Contact

**Email:** [june@june.technology](mailto:june@june.technology)


*or*


**Founder:** Tyler Kline  

**Email:** [tyler@june.technology](mailto:tyler@june.technology)

**Phone:** 952-594-5052

<br/>

## Domain:

june.technology

  `;

  return (
    <>
      <AboutHeader />
      <div className="about-container">
        <div className="about">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {verificationContent}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
}

export default Partners;
