import React from 'react';
import AboutHeader from './AboutHeader';

const Demo = () => {
    return (
        <>
            <AboutHeader />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <iframe 
                    src="https://www.loom.com/embed/4520524638e24ca388c2f32b0af34898?sid=ccda070c-0a91-440c-bb83-e77623a707b9" 
                    frameBorder="0" 
                    webkitAllowFullScreen 
                    mozAllowFullScreen 
                    allowFullScreen 
                    style={{ width: '80%', height: '80%' }}
                ></iframe>
            </div>
        </>
    );
};

export default Demo;