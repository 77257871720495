import React from 'react';
import { Link } from 'react-router-dom';

const Footer = React.memo(() => {
    return (
        <footer className="footer-container">
            <Link className="footer-link" to="/privacy">Privacy Policy</Link>
            <Link className="footer-link" to="/terms">Terms of Service</Link>
            <Link className="footer-link" to="/partners">Partners</Link>
        </footer>
    );
});

export default Footer;